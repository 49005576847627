import React from 'react'

import ResultGraphs from './ResultGraphs'

export default [
  {
    name: 'Prop1',
    title: 'Burst Pressure Plot',
    component: <ResultGraphs />,
  },
]
