import logo from '../../../images/report'

export default {
  title: 'Burst Pressure Report',
  logo,
  columnWidths: [250, 0, 260, 40],
  sections: [
    {
      type: 'heading',
      text: 'Inputs',
    },
    {
      type: 'keywords',
      heading: 'Geometry',
      params: [
        {
          keyword: 'STYPE',
          keywordGroupName: 'SHELL',
          transform: (value) => {
            if(value === 'CY') {
              return 'Cylinder'
            } else if(value === 'SP') {
              return 'Sphere'
            }
            return value
          },
        },
        {
          keyword: 'ID',
          keywordGroupName: 'SHELL',
        },
        {
          keyword: 'ST',
          keywordGroupName: 'SHELL',
        },
      ],
    },
    {
      type: 'keywords',
      heading: 'Material Properties',
      params: [
        {
          keyword: 'SPEC',
          keywordGroupName: 'SHELL',
          materialSpecification: true,
        },
      ],
    },
    {
      type: 'keywords',
      heading: 'Conditions',
      params: [
        {
          keyword: 'STEM',
          keywordGroupName: 'SHELL',
        },
        {
          keyword: 'PBURSTMAX',
          keywordGroupName: 'BURSTP',
          predicate: fields => fields.problemType === 'RT',
        },
      ],
    },
    {
      type: 'heading',
      text: 'Results',
    },
    {
      type: 'keywords',
      heading: '',
      params: [
        {
          keyword: 'TREQE',
          keywordGroupName: 'BURSTP',
          predicate: fields => fields.problemType === 'RT',
        },
        {
          keyword: 'PBURSTE',
          keywordGroupName: 'BURSTP',
          predicate: fields => fields.problemType === 'BP',
        },
      ],
    },
    {
      type: 'image',
      heading: 'Burst Pressure Versus Thickness',
      graphs: [
        {
          name: 'burstGraph',
          keywordGroupName: 'BURSTP',
        },
      ],
    },
  ],
}
