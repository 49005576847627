/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

const Step4 = ({
  problemType, maxPressure, temperature, style,
}) => (
  <div className="pa3">
    <E2GTextField style={style} name="temperature" value={temperature} label="Temperature" type="number" required />
    {problemType === 'RT'
      ? <E2GTextField style={style} name="maxPressure" value={maxPressure} label="Burst Pressure" type="number" required />
      : null}
    <Submit label="Calculate" />
    <div className="f6 mt4">* indicates required field</div>
  </div>
)
Step4.propTypes = {
  maxPressure: PropTypes.number,
  problemType: PropTypes.string,
  temperature: PropTypes.number,
  style: PropTypes.object,
}
const mapStateToProps = state => ({
  maxPressure: state.workflow.fields.maxPressure,
  problemType: state.workflow.fields.problemType,
  temperature: state.workflow.fields.temperature,
  style: state.ui.style,
})
export default connect(mapStateToProps, null)(Step4)
