import { ReactNode } from 'react'

import { CalculatorExports, PlantManagerAsset, PlantManagerComponent } from '@e2grnd/ui-core/dist/types'

import { problemTypeLup } from './sequence/Problems'
import examples from './examples'
import graphs from './graphs'
import params from './params'
import propertyPages from './propertyPages'
import report from './report'
import sequence from './sequence'
import steps from './steps'

const config: CalculatorExports = {
  steps,
  params,
  sequence,
  graphs,
  propertyPages,
  problemTypeLup,
  examples,
  report,
  /**
   * For calculators that operate on a single component, use this function to
   * map the component data to the calculator's parameters. The asset data is
   * also provided in case it is needed.
   */
  assetComponentParamsMapFn: (pmAsset: PlantManagerAsset, pmComponent: PlantManagerComponent) => {
    if(!pmAsset) {
      return { fieldValues: {} }
    }

    // eslint-disable-next-line no-console
    console.log('Mapping asset data:\n\tAsset:', pmAsset, '\n\tComponent:', pmComponent)
    const warnings: ReactNode[] = []
    // errors.push(<Box>Something isn't right. This asset's <Typography color="error" component="span" fontSize="inherit" fontWeight="bold">operating temperature</Typography> is wonky.</Box>)
    let innerDiameter
    if(!pmComponent.innerDiameter) {
      warnings.push('The selected component does not have an inner diameter defined. Thus, we calculate the inner diameter from the outer diameter and the nominal thickness.')
      if(!pmComponent.outerDiameter) {
        warnings.push('The selected component does not have an outer diameter defined.')
      } else {
        if(!pmComponent.nominalThickness) {
          warnings.push('The selected component does not have a nominal thickness defined.')
        } else {
          innerDiameter = pmComponent.outerDiameter - 2.0 * pmComponent.nominalThickness
        }
      }
    } else {
      innerDiameter = pmComponent.innerDiameter
    }

    let nominalThickness
    if(!pmComponent.nominalThickness) {
      warnings.push('The selected component does not have a nominal thickness defined.')
    } else {
      nominalThickness = pmComponent.nominalThickness
    }

    let temperature
    if(!pmComponent.circuit) {
      warnings.push('The selected component does not have a circuit defined.')
      if(!pmComponent.designTemperature) {
        warnings.push('The selected component does not have a design temperature defined.')
      }
      temperature = pmComponent.designTemperature
    } else {
      if(!pmComponent.circuit.operatingTemperature) {
        warnings.push('The selected component does not have a circuit operating temperature defined.')
      }
      temperature = pmComponent.circuit.operatingTemperature
    }

    // let materialSpec;
    // if(!pmComponent.material) {
    //   warnings.push('The selected component does not have a material defined.')
    // } else {
    //   if(!pmComponent.material.asString){
    //     warnings.push('The selected component does not have a full material specification defined.')
    //   } else {
    //     materialSpec = pmComponent.material.asString.split(',')
    //   }
    // }

    let geomType
    if(!pmComponent.geomType) {
      warnings.push('The selected component does not have a geometry type defined.')
    } else {
      if(pmComponent.geomType === 'CY' || pmComponent.geomType === 'SP') {
        geomType = pmComponent.geomType
      } else {
        warnings.push('The selected component does not have a supported geometry type.')
      }
    }

    return {
      warnings,
      fieldValues: {
        insideDiameter: innerDiameter,
        thickness: nominalThickness,
        temperature,
        shellType: geomType,
        // materialSpecification: materialSpec,
      },
    }
  },
}

export default config
