/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

const Step2 = ({
  thickness, insideDiameter, style,
}) => (
  <div className="pa3">
    <E2GTextField style={style} name="insideDiameter" value={insideDiameter} label="Inside Diameter" type="number" required />
    <E2GTextField style={style} name="thickness" value={thickness} label="Nominal Thickness" type="number" required />
    <Submit label="Next" />
    <div className="f6 mt4">* indicates required field</div>
  </div>
)
Step2.propTypes = {
  thickness: PropTypes.number,
  insideDiameter: PropTypes.number,
  style: PropTypes.object,
}
const mapStateToProps = state => ({
  thickness: state.workflow.fields.thickness,
  insideDiameter: state.workflow.fields.insideDiameter,
  style: state.ui.style,
})
export default connect(mapStateToProps, null)(Step2)
