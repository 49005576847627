export default [
  {
    keyword: undefined,
    fieldName: 'pif',
    randomVar: true,
    valueType: 0,
    default: JSON.stringify({
      name: 'Temperature',
      kind: 'nature',
      type: 'discrete',
      parents: null,
      states: {
        states: ['<500', '>=500'],
      },
      evidence: [0, 1],
      CPT: {
        table: [0.5, 0.5],
      },
    }),
    // default: JSON.stringify({
    //   name: 'Temperature',
    //   kind: 'nature',
    //   type: 'continuous',
    //   parents: null,
    //   states: {
    //     // “states”: ["0 to 500”, “500 to 1000”, “1000 to 1500”], // created from levels
    //     levels: [0, 500, 1000, 1500],
    //   },
    //   evidence: [0, 1, 0],
    //   CPT: {
    //     neticaEquation: 'p(temperature|) = NormalDist(temperature, 50, 10)',
    //     sampleSize: 100,
    //   },
    //   // mean: value
    //   // standardDeviation: value
    // }),
    initializer: undefined,
    type: 'input',
    label: 'pif',
  },
  {
    keyword: 'sleepFor',
    valueType: 1,
    fieldName: 'sleepFor',
    default: 5,
    initializer: 5,
    units: {
      USCustomary: 's',
      Metric: 's',
    },
    tooltip: 'How long to sleep for',
    type: 'input',
    label: 'Sleep For',
  },
  {
    keyword: 'timeseries',
    valueType: 0,
    fieldName: 'timeseries',
    default: undefined,
    initializer: undefined,
    units: {
      USCustomary: 's',
      Metric: 's',
    },
    tooltip: 'How long to sleep for',
    type: 'input',
    label: 'Sleep For',
  },
  {
    keyword: 'OverrideResourceMemory',
    valueType: 1,
    fieldName: 'OverrideResourceMemory',
    default: 200,
    min: 200,
    initializer: 200,
    type: 'input',
    label: 'Memory (Mb)',
    tooltip: 'The amount of RAM memory to request for the calculation',
    units: {
      USCustomary: 'Mb',
      Metric: 'Mb',
    },
  },
  {
    keyword: 'OverrideResourceCPU',
    valueType: 2,
    fieldName: 'OverrideResourceCPU',
    default: 1,
    min: 1,
    initializer: 1,
    type: 'input',
    label: 'CPU',
    tooltip: 'The amount of CPU to request for the calculation',
  },
  {
    keyword: 'HasAnalysisFiles',
    valueType: 1,
    fieldName: 'HasAnalysisFiles',
    default: 0,
    min: 0,
    initializer: 0,
    type: 'input',
    label: 'Upload Sample Analysis Files',
    tooltip: 'Setting this option will upload a set of sample analysis files to GCS, so user can download them from the Calculator Footer',
    transform: (value) => {
      if(value) {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === 1) {
        return true
      }
      return false
    },
  },
  {
    keyword: 'randomFailure',
    valueType: 1,
    fieldName: 'randomFailure',
    default: 0,
    min: 0,
    initializer: 0,
    type: 'input',
    label: 'Toggle Random Job Failure',
    tooltip: 'Setting this option will add a random chance that the job will fail to simulate error handling',
    transform: (value) => {
      if(value) {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === 1) {
        return true
      }
      return false
    },
  },
  {
    keyword: 'ResultsFiles',
    valueType: 0,
    fieldName: 'resultsFiles',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Analysis Files',
    tooltip: 'Analysis Files',
  },
  {
    keyword: 'sleptFor',
    valueType: 2,
    fieldName: 'sleptFor',
    initializer: undefined,
    type: 'output',
    units: {
      USCustomary: 's',
      Metric: 's',
    },
  },
]
