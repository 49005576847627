// eslint-disable-next-line simple-import-sort/imports
import 'regenerator-runtime/runtime'
import React, { ComponentProps, PropsWithChildren, useMemo } from 'react'
import { graphql, PageProps, useStaticQuery } from 'gatsby'

import Layout from '@e2grnd/ui-core/dist/components/Layout'
import HelpProvider from '@e2grnd/ui-core/dist/components/TopNav/HelpProvider'
import AppProvider from '@e2grnd/ui-core/dist/contexts/AppProvider'
import AuthedAppProvider from '@e2grnd/ui-core/dist/contexts/AuthedAppProvider'

import { PageFrontmatterOptions } from 'types/page-frontmatter'

import CalculatorDescriptorsProvider from './CalculatorDescriptorsProvider'

import '@e2grnd/ui-core/dist/styles/css/tachyons.min.css'
import '../index.css'
import AssetTableParamsProviderWrapper from './AssetTableParamsProviderWrapper'

export default function CalculatorLayout(props: PropsWithChildren<PageProps<{}, { frontmatter?: PageFrontmatterOptions }>>) {
  const { children, pageContext, location } = props
  const data = useStaticQuery<GatsbyTypes.CalculatorLayoutQueryQuery>(graphql`
    query CalculatorLayoutQuery {
      site {
        buildTime
        siteMetadata {
          package {
            uiCoreVersion
            version
            name
          }
        }
      }
      content {
        platformChangeLogs {
          dateCreated
          datePublished
          slug
          title
          description {
            document
          }
        }
      }
    }
  `)
  const pkg = data.site?.siteMetadata?.package
  const layoutProps: ComponentProps<typeof Layout> = pageContext.frontmatter?.layout || {}
  layoutProps.footerProps = {
    version: pkg?.version,
    uiCoreVersion: pkg?.uiCoreVersion,
    buildTime: data.site?.buildTime,
  }
  const platformChangeLogs = useMemo(() => data.content.platformChangeLogs?.concat(), [data.content.platformChangeLogs])
  layoutProps.topNavProps = {
    platformChangeLogs,
  }
  if(pageContext.frontmatter?.bare) {
    return (
      <CalculatorDescriptorsProvider>
        <HelpProvider>
          {children}
        </HelpProvider>
      </CalculatorDescriptorsProvider>
    )
  }
  if(location.pathname.startsWith('/app')) {
    return (
      <AssetTableParamsProviderWrapper>
        <CalculatorDescriptorsProvider>
          <AppProvider platformName={pkg?.name || undefined} platformVersion={pkg?.version || undefined}>
            <AuthedAppProvider>
              <HelpProvider>
                <Layout {...layoutProps}>{children}</Layout>
              </HelpProvider>
            </AuthedAppProvider>
          </AppProvider>
        </CalculatorDescriptorsProvider>
      </AssetTableParamsProviderWrapper>
    )
  }
  return (
    <AssetTableParamsProviderWrapper>
      <CalculatorDescriptorsProvider>

        <AppProvider>
          <HelpProvider>
            <Layout {...layoutProps}>{children}</Layout>
          </HelpProvider>
        </AppProvider>
      </CalculatorDescriptorsProvider>
    </AssetTableParamsProviderWrapper>
  )
}
