/* eslint-disable camelcase */
import React, { PropsWithChildren } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { parse, stringify } from 'query-string'
import { QueryParamProvider } from 'use-query-params'
import { ReachAdapter } from 'use-query-params/adapters/reach'

import CalculatorDescriptorsProvider from '@e2grnd/ui-core/dist/contexts/CalculatorDescriptorsProvider'
import { CalculatorDescriptor, CalculatorDescriptorMap, CalculatorGroupDescriptorMap } from '@e2grnd/ui-core/dist/types'

import icons from '../meta/icons'

export default function CalcDescriptorsProvider({ children }: PropsWithChildren<{}>) {
  const data = useStaticQuery<GatsbyTypes.ProductRootQuery>(graphql`
    query ProductRoot {
    allCalculatorDescriptor {
      nodes {
        calcId
        descriptor
      }
    }
    content {
      calculatorGroups {
        groupId
        title
        shortTitle
        shortDescription
        members {
          calcId
        }
        description {
          document
        }
      }
      calculators {
        status
        calcId
        sortTitle
        shortTitle
        shortDescription
        title
        equipmentTypes {
          title
        }
        analysisTypes {
          title
        }
        groups {
          groupId
        }
        helpContent {
          document
        }
        marketingBlurbLong {
          document(hydrateRelationships: true)
        }
        changeLog {
          description {
            document
          }
          dateCreated
          datePublished
          title
          slug
        }
      }
    }
  }
  `)
  const descriptors: CalculatorDescriptorMap = data.allCalculatorDescriptor.nodes.reduce((acc, c) => {
    acc[c.calcId!] = c.descriptor
    return acc
  }, {} as CalculatorDescriptorMap)
  const calcGroups: CalculatorGroupDescriptorMap = data.content.calculatorGroups?.reduce((acc, g) => {
    acc[g.groupId!] = {
      groupId: g.groupId!,
      title: g.title!,
      shortTitle: g.shortTitle || undefined,
      shortDescription: g.shortDescription || undefined,
      members: g.members!.map(m => m.calcId!),
      description: g.description?.document,
    }
    return acc
  }, {} as CalculatorGroupDescriptorMap) || {}
  const calcContents = data.content.calculators
  if(!calcContents) {
    throw new Error('No calculator content')
  }
  // Mesh the calculator descriptors with data from the CMS
  const descriptorsWithContentValues = Object.keys(descriptors).reduce((acc, calcId) => {
    const contentEntry = calcContents.find(c => c.calcId === calcId)
    if(!contentEntry) {
      throw new Error(`No content found for calculator ID "${calcId}"`)
    }
    if(!contentEntry.title) {
      throw new Error(`No content found for calculator ID "${calcId}"`)
    }
    const {
      calcId: _discarded,
      ...mappedProps
    } = contentEntry as CalculatorDescriptor
    if(mappedProps.equipmentTypes) {
      mappedProps.equipmentTypes = (mappedProps.equipmentTypes as unknown as GatsbyTypes.CONTENT_EquipmentType[]).map(equipmentType => {
        if(!equipmentType.title) {
          throw new Error('Malformed Content_EquipmentType')
        }
        return equipmentType.title
      })
    }
    if(mappedProps.analysisTypes) {
      mappedProps.analysisTypes = (mappedProps.analysisTypes as unknown as GatsbyTypes.CONTENT_AnalysisType[]).map(analysisType => {
        if(!analysisType.title) {
          throw new Error('Malformed Content_AnalysisType')
        }
        return analysisType.title
      })
    }
    if(mappedProps.groups) {
      mappedProps.groups = (mappedProps.groups as unknown as GatsbyTypes.CONTENT_CalculatorGroup[]).map(calcGroup => {
        if(!calcGroup.groupId) {
          throw new Error('Malformed CONTENT_CalculatorGroup')
        }
        return calcGroup.groupId
      })
    }
    const val: CalculatorDescriptorMap[0] = {
      ...descriptors[calcId],
      ...mappedProps,
    } as CalculatorDescriptorMap[0]
    acc[calcId] = val
    return acc
  }, {} as CalculatorDescriptorMap)
  return (
    <CalculatorDescriptorsProvider
      calculatorDescriptors={descriptorsWithContentValues}
      groupDescriptors={calcGroups}
      iconMap={icons}
    >
      <QueryParamProvider
        adapter={ReachAdapter}
        options={{
          searchStringToObject: parse,
          objectToSearchString: stringify,
        }}
      >
        {children}
      </QueryParamProvider>
    </CalculatorDescriptorsProvider>
  )
}
