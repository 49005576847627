export default {
  USCustomary: [
    {
      title: 'Burst Pressure, Cylinder, 250F',
      fileName: 'ex1-med-cylinder-250F.json',
    },
    {
      title: 'Burst Pressure, Sphere, 400F',
      fileName: 'ex3-large-sphere-400F.json',
    },
    {
      title: 'Burst Thickness, Cylinder, 400F',
      fileName: 'ex2-med-cylinder-400F-thickness.json',
    },
    {
      title: 'Burst Thickness, Sphere, 250F',
      fileName: 'ex4-high-pressure-sphere-thickness.json',
    },
  ],
  Metric: [
    {
      title: 'Burst Pressure, Cylinder, 120C',
      fileName: 'ex1-med-cylinder-metric.json',
    },
    {
      title: 'Burst Pressure, Sphere, 200C',
      fileName: 'ex3-large-sphere-metric.json',
    },
    {
      title: 'Burst Thickness, Cylinder, 200C',
      fileName: 'ex2-med-cylinder-thickness-metric.json',
    },
    {
      title: 'Burst Thickness, Sphere, 100C',
      fileName: 'ex4-high-pressure-sphere-thickness-metric.json',
    },
  ],
}
