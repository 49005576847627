export default {
  USCustomary: [
    {
      title: 'Sleep for 2 seconds',
      fileName: 'example-case-1.json',
    },
  ],
  Metric: [
    {
      title: 'Sleep for 2 seconds',
      fileName: 'example-case-1.json',
    },
  ],
}
