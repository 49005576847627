/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import pick from 'lodash/pick'
import PropTypes from 'prop-types'

import DataGraph from '@e2grnd/ui-core/dist/containers/DataGraph'

const ResultGraphs = ({ graphs }) => {
  const graphToDisplay = pick(graphs, ['burstGraph'])
  const traces = graphToDisplay.burstGraph ? graphToDisplay.burstGraph.traces : undefined
  const layout = graphToDisplay.burstGraph ? graphToDisplay.burstGraph.layout : undefined
  return (
    <div>
      {graphs
        ? (
          <DataGraph plotClass="data-graph-85" data={traces} layout={layout} />
        )
        : null}
    </div>
  )
}

ResultGraphs.propTypes = {
  graphs: PropTypes.object,
}

const mapStateToProps = state => ({
  graphs: state.workflow.response ? state.workflow.response.results.graphs : undefined,
})
export default connect(mapStateToProps, null)(ResultGraphs)
