import React from 'react'
import { GatsbyBrowser } from 'gatsby'

import CalculatorLayout from './src/components/CalculatorLayout'

// export const onClientEntry = () => {
//   if(process.env.NODE_ENV !== 'production') {
//     const whyDidYouRender = require('@welldone-software/why-did-you-render')
//     whyDidYouRender(React, {
//       trackAllPureComponents: true,
//     })
//   }
// }

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = (api) => {
  const { element, props } = api
  return (
    <CalculatorLayout {...props}>
      {element as any}
    </CalculatorLayout>
  )
}

export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}
