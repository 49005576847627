/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'
import StartOver from '@e2grnd/ui-core/dist/containers/StartOver'

const Step5 = ({ problemType, results, style }) => (
  <div>
    {results ? (
      <>
        {problemType === 'RT' ? (
          <E2GTextField style={style} name="exactRequiredThickness" value={results.exactRequiredThickness} label="Burst Thickness" type="number" disabled />
        ) : null}
        {problemType === 'BP' ? (
          <E2GTextField style={style} name="calculatedBurstPressure" value={results.calculatedBurstPressure} label="Burst Pressure" type="number" disabled />
        ) : null}
      </>
    ) : null}
    <StartOver />
    <br />
  </div>
)

Step5.propTypes = {
  problemType: PropTypes.string,
  results: PropTypes.object,
  style: PropTypes.object,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  results: state.workflow.response ? state.workflow.response.results : {},
  style: state.ui.style,
})

export default connect(mapStateToProps)(Step5)
