/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import MaterialField from '@e2grnd/ui-core/dist/containers/MaterialField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'
import { materialFilter } from '@e2grnd/ui-core/dist/core/lib'

const Step3 = ({ materialSpecification, style }) => (
  <div className="pa3">
    <MaterialField
      width="100%"
      acWidth="70%"
      stubName="materialStub"
      name="materialSpecification"
      value={materialSpecification}
      label="Spec, Grade, Year, UNS, CCT, Size"
      style={style}
      designCode="S8-DIV1"
      productForms={['Plate And Sheet', 'Pipe And Tube']}
      maxSearchResults={55}
      filter={materialFilter}
      required
      hintText="Start typing any information about the material"
    />
    <Submit label="Next" />
    <div className="f6 mt4">* indicates required field</div>
  </div>
)

Step3.propTypes = {
  materialSpecification: PropTypes.array,
  style: PropTypes.object,
}

const mapStateToProps = state => ({
  materialSpecification: state.workflow.fields.materialSpecification,
  style: state.ui.style,
})

export default connect(mapStateToProps, null)(Step3)
