export const geometries = [
  {
    selector: {
      label: 'Cylinder',
      value: 'CY',
    },
  },
  {
    selector: {
      label: 'Sphere',
      value: 'SP',
    },
  },
]

export default geometries
