import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import OpenStepper from '@e2grnd/ui-core/dist/containers/OpenStepper'
import RadioField, { RadioFieldOption } from '@e2grnd/ui-core/dist/containers/RadioField'

export const problemTypeLup = {
  RT: 'Thickness',
  BP: 'Pressure',
}

const Problems = ({ problemType }) => (
  <div>
    <RadioField name="problemType" value={problemType} reInitialize >
      <RadioFieldOption value="BP" label="Determine Burst Pressure for a Specified Thickness" />
      <RadioFieldOption value="RT" label="Determine Burst Thickness for a Specified Pressure" />
    </RadioField>
    <OpenStepper label="Next" name="problemType" value={problemType} />
  </div>
)

Problems.propTypes = {
  problemType: PropTypes.string,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
})

export default connect(mapStateToProps, null)(Problems)
