import React from 'react'

import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'

export default [
  {
    name: 'Step1',
    title: 'Select Geometry',
    content: (
      <Step1 />
    ),
  },
  {
    name: 'Step2',
    title: 'Enter Dimensions',
    content: (
      <Step2 />
    ),
  },
  {
    name: 'Step3',
    title: 'Select Material',
    content: (
      <Step3 />
    ),
  },
  {
    name: 'Step4',
    title: 'Specify Conditions',
    content: (
      <Step4 />
    ),
  },
  {
    name: 'Step5',
    title: {
      BP: 'Determine Burst Pressure',
      RT: 'Determine Burst Thickness',
    },
    content: (
      <Step5 />
    ),
  },
]
