import { ReactNode } from 'react'

import { CalculatorExports, PlantManagerAsset, PlantManagerComponent } from '@e2grnd/ui-core/dist/types'

import examples from './examples'
import params from './params'
import steps from './steps'

export const config: CalculatorExports = {
  steps,
  params,
  examples,
  /**
   * Use this function to map the asset data to the calculator's parameters.
   */
  assetParamsMapFn: (pmAsset: PlantManagerAsset, pmComponents: PlantManagerComponent[]) => {
    if(!pmAsset) {
      return { fieldValues: {} }
    }
    // eslint-disable-next-line no-console
    console.log('Mapping asset data:\n\tAsset:', pmAsset, '\n\tComponent:', pmComponents)
    const warnings: ReactNode[] = []
    const errors: ReactNode[] = []
    // todo: define a reusable function for this in ui-core that takes in params and excluded keywords and returns the key/value pair object
    // const excludedKeywords = ['OverrideResourceMemory']
    // const inputParams = params.filter((param) => param.type === 'input')
    //   .filter((param) => typeof param.keyword === 'string' && !excludedKeywords.includes(param.keyword))
    //   .reduce((acc, param) => ({ [param.keyword as string]: param.default, ...acc }), {} as Record<string, any>)
    return {
      // warnings: ['This is a warning message'],
      // errors: ['This is an error message'],
      errors,
      warnings,
      fieldValues: {
        // ...inputParams,
        sleepFor: 5,
        OverrideResourceMemory: 222,
      },
    }
  },
  /**
   * For calculators that operate on a single component, use this function to
   * map the component data to the calculator's parameters. The asset data is
   * also provided in case it is needed.
   */
  /*
  assetComponentParamsMapFn: (pmAsset: PlantManagerAsset, pmComponent: PlantManagerComponent) => {
    if(!pmAsset) {
      return { fieldValues: {} }
    }
    // eslint-disable-next-line no-console
    console.log('Mapping asset data:\n\tAsset:', pmAsset, '\n\tComponent:', pmComponent)
    const warnings: ReactNode[] = []
    const errors: ReactNode[] = []
    // errors.push(<Box>Something isn't right. This asset's <Typography color="error" component="span" fontSize="inherit" fontWeight="bold">operating temperature</Typography> is wonky.</Box>)
    if(!pmComponent.innerDiameter) {
      warnings.push('The selected component does not have an inner diameter defined.')
    }
    return {
      // warnings: ['This is a warning message'],
      // errors: ['This is an error message'],
      errors,
      warnings,
      fieldValues: {
        sleepFor: 5,
        OverrideResourceMemory: 222,
      },
    }
  },
  */
}

export default config
