import React from 'react'
import { connect } from 'react-redux'
import { Box } from '@mui/material'

import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'
import StartOver from '@e2grnd/ui-core/dist/containers/StartOver'

const Step2 = ({ results, style }) => {
  // return {
  //   results: state.workflow.response.results,
  //   style: state.ui.style,
  // }
  return (
    <div>
      <E2GTextField style={style} name="sleptFor" value={results?.sleptFor} label="Slept For (s)" type="number" disabled />
      <Box mt={2}>
        <StartOver />
      </Box>
    </div>
  )
}

const mapStateToProps = (state) => ({
  results: state.workflow.response.results,
  style: state.ui.style,
})

export default connect(mapStateToProps, null)(Step2)
