/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'
import TimeseriesDataInputField from '@e2grnd/ui-core/dist/containers/TimeseriesDataInputField'
import ToggleField from '@e2grnd/ui-core/dist/containers/ToggleField'

const Step1 = ({ sleepFor, OverrideResourceMemory, OverrideResourceCPU, HasAnalysisFiles, randomFailure, timeseries, style }) => (
  <div>
    <TimeseriesDataInputField label="Timeseries" name="timeseries" value={timeseries} />
    <E2GTextField name="sleepFor" style={style} value={sleepFor} label="Sleep For (s):" type="integer" />
    <E2GTextField name="OverrideResourceMemory" style={style} value={OverrideResourceMemory} label="Memory (Mbs):" type="number" />
    <E2GTextField name="OverrideResourceCPU" style={style} value={OverrideResourceCPU} label="CPU:" type="number" />
    <ToggleField name="HasAnalysisFiles" style={style} value={HasAnalysisFiles} label="Simulate Analysis Files" />
    <ToggleField name="randomFailure" style={style} value={randomFailure} label="Toggle Random Failure" />
    <Submit label="Calculate" disabled={undefined} onSubmit={undefined} />
  </div>
)

Step1.propTypes = {
  style: PropTypes.object,
}

const mapStateToProps = (state) => ({
  sleepFor: state.workflow.fields.sleepFor,
  OverrideResourceMemory: state.workflow.fields.OverrideResourceMemory,
  OverrideResourceCPU: state.workflow.fields.OverrideResourceCPU,
  HasAnalysisFiles: state.workflow.fields.HasAnalysisFiles,
  randomFailure: state.workflow.fields.randomFailure,
  style: state.ui.style,
  timeseries: state.workflow.fields.timeseries,
})

export default connect(mapStateToProps, null)(Step1)
