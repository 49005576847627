export default [
  {
    traces: [
      {
        keyword: 'BURST_PRES_GRAPH1',
        title: 'Simplified Method',
        mode: 'lines+markers',
        type: 'scatter',
        line: {
          width: 1,
        },
        marker: {
          size: 4.5,
        },
        units: {
          USCustomary: ['in', 'psi'],
          Metric: ['mm', 'MPa'],
        },
      },
      {
        keyword: 'BURST_PRES_GRAPH2',
        title: 'Exact Method',
        mode: 'lines+markers',
        type: 'scatter',
        line: {
          width: 1,
        },
        marker: {
          size: 4.5,
        },
        units: {
          USCustomary: ['in', 'psi'],
          Metric: ['mm', 'MPa'],
        },
      },
    ],
    name: 'burstGraph',
    title: 'Burst Pressure Versus Thickness',
    legend: {
      xanchor: 'left',
      x: 0.01,
      yanchor: 'top',
      y: 0.99,
      bordercolor: '#000000',
      borderwidth: 1,
    },
    xAxis: {
      title: {
        USCustomary: 'Thickness (in)',
        Metric: 'Thickness (mm)',
      },
      rangemode: 'tozero',
    },
    yAxis: {
      title: {
        USCustomary: 'Pressure (psi)',
        Metric: 'Pressure (MPa)',
      },
      rangemode: 'tozero',
    },
    keywordGroupName: 'BURSTP',
  },
]
